import { lazy } from "react";
import {
  BackgroundContainer
} from "./styles";

const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const MintBlock = lazy(() => import ("../../components/Mint/MintBlock"));



const Mint = () => {
  return (
    <>
    <BackgroundContainer>


            <MintBlock/>

            
              

        <ScrollToTop />
    </BackgroundContainer>
    </>
  );
};
export default Mint;
